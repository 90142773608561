import React from "react";
import Layout from "../../components/Layout";
import UpdatePaymentForm from "../../components/UpdatePayment";

const UpdatePaymentPage = () => (
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="section">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              Update Payment
            </h2>
            <p>
              Unfortunately, our records show we were not able to complete your
              subscription transaction. Please provide a valid method of payment
              below. Or, if you think this is an error, contact support.
            </p>
            <br />
            <UpdatePaymentForm />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const UpdatePaymentPageFinal = () => (
  <Layout>
    <UpdatePaymentPage />
  </Layout>
);

export default UpdatePaymentPageFinal;
